* {
  scrollbar-color: #ff7f53 #f0f4f8;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: #f4f5f6 !important;
}

html {
  scroll-behavior: smooth;
}

.owl-dots {
  display: none;
}

.desc {
  display: block;
  opacity: 0;
  transform: translateY(33px);
  transition: .5s;
  visibility: hidden;
}

@keyframes fill {
  0% {
    transform: translateY(20%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-20%);
  }
}

.bttn {
  display: block;
  opacity: 0;
  transform: translateY(33px);
  transition: .5s;
  visibility: hidden;
}

@media screen and (max-width :900px) {
  .desc {
    display: block;
    opacity: 1;
    transform: translateY(23px);
    transition: .5s;
    visibility: visible;
  }

  .bttn {
    display: block;
    opacity: 1;
    transform: translateY(12px);
    transition: .5s;
    visibility: visible;
  }

  .title {
    display: block;
    transform: translateY(0);
    transition: .5s;
    font-size: 14px !important;

  }
}


.title {
  display: block;
  transform: translateY(25px);
  transition: .5s;

}

.main :hover {
  transition: all .5s;
  cursor: pointer;

  .desc {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .bttn {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);

  }

  .title {
    transform: translateY(0);
  }

  .img {
    background-color: #f0f4f8;
    box-shadow: 0 8px 13px #00000026;
    transition: .5s;

  }
}

.css-1aenusr-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #ff7f53 !important;
  color: #fff !important;
}

.css-1n2qkgd-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #ff7f53 !important;
  color: #fff !important;
}

.css-3k6eqa-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #ff7f53 !important;
  color: #fff !important;
}

/* .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
  color: #fff;
} */

.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  color: #fff;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
  margin-top: 0;
  border-radius: 27px 27px 0px 0px;
  background: #f4f5f6;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-top: 16px 0px;
  border-radius: 27px 27px 0px 0px;
  background-color: #f4f5f6;
}

.css-eqpfi5-MuiAccordionSummary-content {
  color: #000;
  font-weight: 700;
}

/* .bttn::before{
  content: "";
  background-color: #ff7f53;
  height: 2px;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
} */

.css-19kzrtu {
  position: absolute;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* // ________________________________________ owl carousal ------------------ */


.owl-theme .owl-dots .owl-dot {
  display: none !important;
}

.owl-theme {
  position: relative;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  height: 100px;
  display: flex;
  background-color: #28282866 !important;
  width: 55px;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px !important;
  box-shadow: 0px 1px 4px rgba(34, 34, 34, 0.14);
  font-size: 70px !important;
  font-weight: 200 !important;
  color: #fff !important;
  transition: all 0.5s;
}

@media only screen and (max-width:768px) and (min-width :319px) {
  /* .owl-carousel .owl-nav button.owl-prev {
    top: 40% !important;
    left: 0;
  }

  .owl-carousel .owl-nav button.owl-next {
    top: 30% !important;
    right: 0;
  } */
}

@media only screen and (max-width:2500px) and (min-width :1441px) {
  .css-doggxg-MuiGrid-root {
    padding: 95px 137px 82px !important;
  }
}



.owl-carousel .owl-nav button.owl-prev {
  top: 40%;
  left: 0;
}

.owl-carousel .owl-nav button.owl-next {
  top: 40%;
  right: 0;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #216A32;
}





@media only screen and (max-width:1440px) {
  .innerImg {
    width: none;
  }
}

@media only screen and (max-width:1439px) and (min-width :1024px) {
  .innerImg {
    width: 65%;
    top: 27% !important;
    left: 16% !important;
  }
}

@media only screen and (max-width:1024px) and (min-width :768px) {
  .innerImg {
    width: 50%;
    top: 30% !important;
    left: 22% !important;
  }
}

@media only screen and (max-width:767px) and (min-width :425px) {
  .innerImg {
    width: 70%;
    top: 23% !important;
    left: 14% !important;
  }

}

@media only screen and (max-width:425px) and (min-width :375px) {
  .innerImg {
    width: 70%;
    top: 23% !important;
    left: 14% !important;
  }
}

@media only screen and (max-width:375px) and (min-width :0px) {
  .innerImg {
    width: 70%;
    top: 23% !important;
    left: 14% !important;
  }
}

@media only screen and (max-width:375px) and (min-width :320px) {
  .custom {
    margin-left: 0;
  }
}

@media only screen and (max-width:320px) and (min-width :0px) {
  .custom {
    margin-left: 50px;
  }
}

@media (min-width : 900px) {
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    height: auto !important;
  }
}

.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper {
  background-image: none !important;
}

@keyframes circularMotion {
  0% {
    transform: rotate(0deg) translate(0px);
  }

  50% {
    transform: rotate(360deg) translate(30px);
  }
}

.mainBox:hover {
  .innerbox {
    transform: translateY(0%);
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

.innerbox {
  transform: translateY(-10%);
  opacity: 0;
}

@keyframes pulsate {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.owl-item.active.center .innerbox {
  transform: translateY(0%);
  opacity: 1;
  -webkit-transform: translateY(0%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.TourtoUgam .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d9d9d9 !important;
  margin: 0 6px;
}

.TourtoUgam .owl-dot.active {
  background-color: #ff7f53 !important;
}

.TourtoUgam .owl-dots {
  text-align: center;
}

.AwardSlider .owl-carousel .owl-nav button.owl-prev {
  top: 44% !important;
  left: -38px !important;
  color: #fff !important;
}

.AwardSlider .owl-carousel .owl-nav button.owl-next {
  top: 44% !important;
  right: -38px !important;
  color: #fff !important;
}

.Awardmain .AwardSlider .owl-carousel .owl-nav button.owl-prev,
.Awardmain .AwardSlider .owl-carousel .owl-nav button.owl-next {
  height: 84px !important;
  background-color: #000c !important;
  width: 84px;
  font-size: 56px !important;
  color: #ffff !important
}

.Awardmain .AwardSlider .owl-carousel .owl-nav button.owl-prev:hover {
  background-color: #ff7f53 !important;
  transition: .5s
}

.Awardmain .AwardSlider .owl-carousel .owl-nav button.owl-next:hover {
  background-color: #ff7f53 !important;
  transition: .5s
}

@media only screen and (max-width:1024px) and (min-width :768px) {

  .Awardmain .AwardSlider .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    height: 100px !important;
    width: 55px !important;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    height: 100px !important;
    width: 55px !important;
  }

  .AwardSlider .owl-carousel .owl-nav button.owl-next {
    top: 46% !important;
    right: -22px !important;
  }

  .AwardSlider .owl-carousel .owl-nav button.owl-prev {
    top: 46% !important;
    left: -22px !important;
  }
}

@media only screen and (max-width:767px) and (min-width :425px) {

  /* .Awardmain .AwardSlider .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    height: 100px;
    display: flex;
    background-color: #28282866 !important;
    width: 55px;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px !important;
    box-shadow: 0px 1px 4px rgba(34, 34, 34, 0.14);
    font-size: 70px !important;
    font-weight: 200 !important;
    color: grey;
    transition: all 0.5s;
  } */

  .AwardSlider .owl-carousel .owl-nav button.owl-next {
    top: 42% !important;
    right: -13px !important;
  }

  .AwardSlider .owl-carousel .owl-nav button.owl-prev {
    top: 42% !important;
    left: -13px !important;
  }
}

@media only screen and (max-width:425px) and (min-width :0px) {

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    height: 60px;
    display: flex;
    background-color: #28282866 !important;
    width: 55px;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px !important;
    box-shadow: 0px 1px 4px rgba(34, 34, 34, 0.14);
    font-size: 40px !important;
    font-weight: 200 !important;
    color: #fff !important;
    transition: all 0.5s;
  }

  .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    height: 60px !important;
    display: flex;
    background-color: #28282866 !important;
    width: 55px;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px !important;
    box-shadow: 0px 1px 4px rgba(34, 34, 34, 0.14);
    font-size: 40px !important;
    font-weight: 200 !important;
    color: #fff !important;
    transition: all 0.5s;
  }
}