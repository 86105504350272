@media screen and (min-width:425px) and (max-width: 460px) {
    .map_indicator {
        left: 80px !important;
        top: -68px !important;
    }
}

@media screen and (min-width:375px) and (max-width: 424px) {
    .map_indicator {
        left: 48px !important;
        top: -82px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
    .map_indicator {
        top: -59% !important;
        left: 4% !important;
    }
}